<template>
  <div>
    <Pane :bottom="0"
          :showRemarks="false">
      <a-tabs v-model="activeKey"
              :tabBarStyle="{
        margin: '0',
      }">
        <a-tab-pane v-for="item in tabList"
                    :key="item.key"
                    :tab="item.name"
                    :disabled="item.disabled"></a-tab-pane>
      </a-tabs>

      <template v-slot:extra>
        <div class="export-download">
          <div class="button"
               @click="exportForm"
               v-if="isView">
            <img class="export"
                 :src="exportImage"
                 alt="" />
            <span>导出文档</span>
          </div>
          <div class="button"
               @click="download">
            <img class="download"
                 :src="downloadImage"
                 alt="" />
            <span>下载模板</span>
          </div>
        </div>
      </template>
    </Pane>
    <div class="container">
      <WorkContact ref="workContackRef"
                   v-show="activeKey === '0' && pData.projectName"
                   :msg="pData"></WorkContact>
      <WeekSummary ref="weekSummaryRef"
                   v-show="activeKey === '1' && pData.projectName"
                   :msg="pData"></WeekSummary>
      <MonthSummary ref="monthSummaryRef"
                    v-show="activeKey === '2' && pData.projectName"
                    :msg="pData"></MonthSummary>
    </div>

  </div>
</template>
<script>
import WorkContact from "./components/WorkContact";
import WeekSummary from "./components/WeekSummary";
import MonthSummary from "./components/MonthSummary";

import { fetchDetail } from "@/api/epc";

export default {
  name: "construPro",
  components: {
    WorkContact,
    WeekSummary,
    MonthSummary,
    // FileUpload,
  },
  data() {
    return {
      exportImage: require("@/assets/epc/export.png"),
      downloadImage: require("@/assets/epc/download.png"),

      activeKey: "0",
      tabList: [
        { key: "0", name: "工作联系单", disabled: false },
        { key: "1", name: "周度总结", disabled: false },
        { key: "2", name: "月度总结", disabled: false },
      ],

      form: this.$form.createForm(this),
      pid: "", // 项目id
      pData: {},

      isView: false,
    };
  },
  mounted() {
    const { query } = this.$route;
    const { activeKey, pid, id, isView } = query || {};

    if (!pid) {
      return;
    }
    this.activeKey = activeKey || "0";
    this.pid = pid;
    this.isView = Boolean(isView) || false;

    if (id) {
      this.setTab();
    }
    this.getProjectInfo();
  },
  methods: {
    setTab() {
      this.tabList.forEach((item) => {
        if (item.key !== this.activeKey) {
          item.disabled = true;
        }
      });
    },
    getProjectInfo() {
      fetchDetail(this.pid).then((res) => {
        this.pData = {
          pid: this.pid,
          designCode: res.designCode,
          projectName: res.name,
        };
      });
    },

    download() {
      switch (this.activeKey) {
        case "0":
          this.$refs.workContackRef.download();
          break;
        case "1":
          this.$refs.weekSummaryRef.download();
          break;
        case "2":
          this.$refs.monthSummaryRef.download();
          break;
      }
    },
    exportForm() {
      switch (this.activeKey) {
        case "0":
          this.$refs.workContackRef.exportForm();
          break;
        case "1":
          this.$refs.weekSummaryRef.exportForm();
          break;
        case "2":
          this.$refs.monthSummaryRef.exportForm();
          break;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px 20px;
}
</style>
